<template>
  <v-container fluid fill-height>
    <v-row wrap align-center>
      <v-col>
        <v-card>
          <v-card-text>
            <p class="pa-4 display-1">Este es tu pedido</p>
            <v-list three-line>
              <template>
                <v-list-item
                  v-for="(item, index) in carrito.productos"
                  :key="index"
                >
                  <v-list-item-avatar tile>
                    <v-img :src="item.foto"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      @click="$router.push(`/productos/${item.slug}`)"
                      >{{ item.cantidad }} x
                      <strong>{{ item.nombre }}</strong></v-list-item-title
                    >
                    <v-list-item-subtitle
                      >$ {{ item.total }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text></v-list-item-action-text>

                    <v-btn icon @click="editarItem(item)"
                      ><v-icon color="grey lighten-1">
                        mdi-pencil
                      </v-icon></v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
            <h2 class="pa-4 mb-4 text-right">
              Subtotal ... $ {{ carrito.subtotal }}
            </h2>
            <v-btn block large color="green" dark @click="confirmarPedido()"
              ><v-icon>mdi-check</v-icon> Confirmar pedido</v-btn
            >
            <v-btn
              block
              color="primary"
              class="mt-2"
              @click="$router.push('/tienda')"
              >Seguir comprando</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "Carrito",
  metaInfo: {
      title: 'Carrito',
    },
  computed: {
    ...mapState(["carrito"]),
  },
  methods: {
    ...mapMutations(['dialogAgregarCarrito']),
    confirmarPedido() {
      this.$router.push({ name: "Comprar" });
    },
    editarItem(item) {
      item.editar = true
      this.dialogAgregarCarrito({ item, estado: true })
    }
  },
  created() {
    if (!this.carrito.productos.length) this.$router.push({ name: "Tienda" });
  },
};
</script>
